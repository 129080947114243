import * as applicationConstants from './application.actions.constants';

const initialState = {
  promoterData: {},
  address: {},
  bankDetails: {},
  compareDetails: {},
  deviceDetails: {},
  comment: '',
  condition: '',
  photos: {},
  transactionDetails: {},
  dedupeDetails: {},
  programList: [],
  subProgramList: [],
  digiLocker: {},
  cpoDocument: {},
  verificationStatus: {},
  supplierDetails: {},
  compareKycDetails: [],
};

const ApplicationReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case applicationConstants.SET_BUSINESS_DETAILS:
      return { ...state, promoterData: data };
    case applicationConstants.SET_ADDRESS_DETAILS:
      return { ...state, address: data };
    case applicationConstants.SET_BANK_DETAILS:
      return { ...state, bankDetails: data };
    case applicationConstants.SET_COMPARE_DETAILS:
      return { ...state, compareDetails: data };
    case applicationConstants.SET_DEVICE_DETAILS:
      return { ...state, deviceDetails: data };
    case applicationConstants.SET_COMMENT_NOTES:
      return { ...state, comment: data };
    case applicationConstants.SET_CONDITION_NOTES:
      return { ...state, condition: data };
    case applicationConstants.SET_PHOTOS_DETAILS:
      return { ...state, photos: data };
    case applicationConstants.SET_TRANSACTION_DETAILS:
      return { ...state, transactionDetails: data };
    case applicationConstants.SET_DEDUPE_DETAILS:
      return { ...state, dedupeDetails: data };
    case applicationConstants.SET_PROGRAM_LIST:
      return { ...state, programList: data };
    case applicationConstants.SET_SUB_PROGRAM_LIST:
      return { ...state, subProgramList: data };
    case applicationConstants.SET_DIGILOCKER_DETAILS:
      return { ...state, digiLocker: data };
    case applicationConstants.SET_CPO_DOCUMENT:
      return { ...state, cpoDocument: data };
    case applicationConstants.SET_COMPARE_KYC_DETAILS:
      return { ...state, compareKycDetails: data };
    case applicationConstants.SET_SUPPLIER_DETAILS:
      return { ...state, supplierDetails: data };
    case applicationConstants.SET_VERIFICATION_STATUS:
      return {
        ...state,
        verificationStatus: {
          ...state.verificationStatus,
          [data.docid]: data.status,
        },
      };
    default:
      return state;
  }
};

export default ApplicationReducer;
