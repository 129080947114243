import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { API_POST_OFFER_ACTIVE } from '../../../utils/APIUrls';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { POST } from '../../../utils/webAPI.service';
import ReactTable from 'react-table';
import 'react-datepicker/dist/react-datepicker.css';
import { applicationColumns } from './offer.config';
import HistoryIcon from '../../../assets/images/history.png';
import OfferHistoryModal from './offerHistoryModal';
import UnlockLimitModal from './unlockLimitModal';
import CredoModal from '../../../components/CredoModal';
import { getActiveLoan } from './offer.actions';
import Button from '../../../components/Button/index';
import { loaderModal } from '../../../store/actions';

class Offer extends Component {
  state = {
    activeButton: true,
    isModalOpen: false,
    subventionFlag: 'No',
    subventionBreakCharges: 'Yes',
    flatChargeFlag: 'No',
    marginFlag: 'No',
    settlementSubOrder: null,
    isSetupFeesUpfront: null,
  };
  componentDidMount = async () => {
    let { actions, match } = this.props;
    let { loanId } = match.params;
    let token = localStorage.getItem('token');
    actions.loaderModal(true);
    try {
      if (token) {
        await actions.getActiveLoan(loanId);
        actions.loaderModal(false);
        this.setLoanLimitsFlags();
      } else {
        let { history } = this.props;
        alert('Unauthorized access');
        actions.loaderModal(false);
        history.push('/');
      }
    } catch {
      actions.loaderModal(false);
    }
  };

  setLoanLimitsFlags = () => {
    const { loanLimits } = this.props;
    const subventionFlag = loanLimits?.subvention?.applicable ? 'Yes' : 'No';
    const marginFlag = loanLimits?.margin?.applicable ? 'Yes' : 'No';
    this.setState({ subventionFlag, marginFlag });
  };

  componentDidUpdate(prevProps) {
    const { loanLimits: prevLoanLimits } = prevProps;
    const { loanLimits: currentLoanLimits } = this.props;
    if (prevLoanLimits !== currentLoanLimits) {
      this.setLoanLimitsFlags();
    }
  }
  toggleModal = (modalType, modalName) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      modalName,
    });
  };

  submitOffer = (e) => {
    e.preventDefault();
    let { match, activeLoans } = this.props,
      { loanId } = match.params,
      {
        extension,
        extensionInterestRate,
        gracePeriod,
        interestRate,
        limitValidity,
        marginFlag,
        overdueIntRate,
        setupFees,
        subventionFlag,
        subventionInterestRate,
        subventionPeriodInDays,
        tenure,
        isSetupFeesUpfront,
        minInvAmt,
        maxInvAmt,
        marginPercentage,
      } = this.state;

    let data = {
      extension,
      extensionInterestRate,
      gracePeriod,
      interestRate,
      limitValidity,
      marginPercentage: marginFlag === 'Yes' ? marginPercentage : '',
      overdueIntRate,
      setupFees,
      minInvAmt,
      maxInvAmt,
      subventionInterestRate:
        subventionFlag === 'Yes' ? subventionInterestRate : '',
      subventionPeriodInDays:
        subventionFlag === 'Yes' ? subventionPeriodInDays : '',
      tenure,
      isSetupFeesUpfront,
      loanId,
    };
    if (Number(minInvAmt) >= Number(maxInvAmt)) {
      alert('Max Invoice Amount should be greater than the Min Invoice Amount');
      return;
    }
    if (
      Number(minInvAmt) > Number(activeLoans?.offerLimitAmt) ||
      Number(maxInvAmt) > Number(activeLoans?.offerLimitAmt)
    ) {
      alert(
        'Max Invoice Amount and Min Invoice Amount should be smaller than Sanction Limit'
      );
      return;
    }

    if (
      subventionFlag === 'No' ||
      (subventionFlag === 'Yes' &&
        Number(
          subventionPeriodInDays !== undefined ? subventionPeriodInDays : 0
        ) <= Number(tenure))
    ) {
      if (isSetupFeesUpfront !== null) {
        POST(API_POST_OFFER_ACTIVE, data)
          .then(({ data }) => {
            if (data.code === 200) {
              alert('Offer Activated And Sent Successfully');
              let { actions, match } = this.props,
                { loanId } = match.params;
              actions.getActiveLoan(loanId);
            } else if (data.code === 999 || data.code === 401) {
              alert(data?.message);
            } else {
              alert('Something went wrong');
            }
          })
          .catch((err) => {
            alert('Something went wrong');
            throw err;
          });
      } else {
        alert('Please Select Setup Fees Upfront');
      }
    } else {
      alert(
        'The subvention period should be equal to or less than the standard tenure'
      );
    }
  };

  settlementSubOrders = (ele) => {
    if (ele === 'vertical_ipc') {
      return 'Vertical IPC';
    } else if (ele === 'vertical_pic') {
      return 'Vertical PIC';
    }
    return null;
  };

  isSetupFeesUpfront = (ele) => {
    if (ele === true) {
      return 'Yes';
    } else if (ele === false) {
      return 'No';
    } else {
      return '_';
    }
  };

  render() {
    let {
        activeLoans,
        promoterData,
        match,
        loanLimits,
        allOffers,
        regenerateCheck,
      } = this.props,
      { userId } = match.params,
      { isModalOpen, modalType, modalName } = this.state,
      applicationStatus = activeLoans?.status?.toLowerCase();
    let { subventionFlag, marginFlag } = this.state;
    return (
      <>
        <div className='offer_wrapper container'>
          <Helmet>
            <meta charSet='utf-8' />
            <title> Offer</title>
          </Helmet>
          <div className='card'>
            <div className='row '>
              <h3 className='col-md-10 card_heading_secondary m-heading'>
                Activate Offer
              </h3>
              <h5
                className='col-md-2  add_button'
                onClick={() => this.toggleModal(OfferHistoryModal)}
              >
                <img src={HistoryIcon} alt='icon' />
                <span>Offer History</span>
              </h5>
            </div>
            <div className='row generic_button_box'>
              <div className='col-md-2 text-grey '>
                Entity Name
                <div className='text-bold'>
                  {promoterData?.entityName ?? '-'}
                </div>
              </div>
              <div className='col-md-2 text-grey'>
                User ID
                <div className='text-bold'>{userId}</div>
              </div>
              <div className='col-md-2 text-grey'>
                Offer ID
                <div className='text-bold'>{activeLoans?.offerId}</div>
              </div>
              <div className='col-md-2 text-grey'>
                Program
                <div className='text-bold'>
                  {activeLoans?.programName ?? '-'}
                </div>
              </div>
              <div className='col-md-2 text-grey'>
                Sub-Program
                <div className='text-bold'>
                  {activeLoans?.subProgramName ?? '-'}
                </div>
              </div>
              <div className='col-md-2 text-grey'>
                Loan Service provider
                <div className='text-bold'>{activeLoans?.lspName ?? '-'}</div>
              </div>
              <div className='col-md-2 text-grey'>
                Sanction Limit
                <div className='text-bold'>
                  {rupeeDecimalFormatter(activeLoans?.offerLimitAmt)}
                </div>
              </div>
            </div>

            {applicationStatus === 'pending' ? (
              <>
                <div className='card'>
                  <form onSubmit={(e) => this.submitOffer(e)}>
                    <div className='row pl-4'>
                      <div className='col-md-3 pl-0'>
                        <div className=' text-grey'>
                          Limit Validity
                          <input
                            min={loanLimits?.minLimitTenureInDays}
                            max={loanLimits?.maxLimitTenureInDays}
                            placeholder='Enter Days'
                            value={this.state.limitValidity}
                            type='number'
                            required
                            onChange={(e) =>
                              this.setState({
                                limitValidity: e.target.value,
                              })
                            }
                          />
                          <div className='limit-parameters'>
                            Between {loanLimits?.minLimitTenureInDays} -
                            {loanLimits?.maxLimitTenureInDays} days
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className=' text-grey'>
                          Standard Tenure
                          <input
                            min={loanLimits?.minTenure}
                            max={loanLimits?.maxTenure}
                            placeholder=' Enter Days'
                            value={this.state.tenure}
                            type='number'
                            required
                            onChange={(e) =>
                              this.setState({
                                tenure: e.target.value,
                              })
                            }
                          />
                          <div className='limit-parameters'>
                            Between {loanLimits?.minTenure} -
                            {loanLimits?.maxTenure} days
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className=' text-grey'>
                          Min Invoice Amount
                          <input
                            placeholder=' Enter Min Invoice Amount'
                            value={this.state.minInvAmt}
                            type='number'
                            required
                            onChange={(e) =>
                              this.setState({
                                minInvAmt: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className=' text-grey'>
                          Max Invoice Amount
                          <input
                            placeholder=' Enter  Max Invoice Amount'
                            value={this.state.maxInvAmt}
                            type='number'
                            required
                            onChange={(e) =>
                              this.setState({
                                maxInvAmt: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row pl-4'>
                      <div className='col-md-3 pl-0'>
                        <div className=' text-grey'>
                          Interest Rate
                          <input
                            min={loanLimits?.minIntRate}
                            max={loanLimits?.maxIntRate}
                            placeholder=' Enter Percentage'
                            value={this.state.interestRate}
                            type='number'
                            required
                            onChange={(e) =>
                              this.setState({
                                interestRate: e.target.value,
                              })
                            }
                          />
                          <div className='limit-parameters'>
                            Between {loanLimits?.minIntRate}% -
                            {loanLimits?.maxIntRate}%
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 '>
                        <div className=' text-grey'>
                          Grace Period
                          <input
                            min={loanLimits?.minGracePeriodDays}
                            max={loanLimits?.maxGracePeriodDays}
                            placeholder=' Enter Days'
                            value={this.state.gracePeriod}
                            type='number'
                            required
                            onChange={(e) =>
                              this.setState({
                                gracePeriod: e.target.value,
                              })
                            }
                          />
                          <div className='limit-parameters'>
                            Between {loanLimits?.minGracePeriodDays} -
                            {loanLimits?.maxGracePeriodDays} days
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 '>
                        <div className='text-grey'>
                          Repayment Settlement Sub Order
                          <input
                            value={this.settlementSubOrders(
                              loanLimits?.settlementSubOrder
                            )}
                            required
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row pl-4'>
                      <div className='col-md-3 pl-0'>
                        <div className=' text-grey'>
                          Extension
                          <input
                            min={loanLimits?.minExtension}
                            max={loanLimits?.maxExtension}
                            placeholder=' Enter Days'
                            value={this.state.extension}
                            type='number'
                            required
                            onChange={(e) =>
                              this.setState({
                                extension: e.target.value,
                              })
                            }
                          />
                          <div className='limit-parameters'>
                            Between {loanLimits?.minExtension} -
                            {loanLimits?.maxExtension} days
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className=' text-grey'>
                          Extension Interest Rate(APR%)
                          <input
                            max={loanLimits?.maxExtensionInterestRate}
                            min={loanLimits?.minExtensionInterestRate}
                            required
                            placeholder='Enter Percentage'
                            value={this.state.extensionInterestRate}
                            type='number'
                            onChange={(e) =>
                              this.setState({
                                extensionInterestRate: e.target.value,
                              })
                            }
                          />
                          <div className='limit-parameters'>
                            <div className='limit-parameters'>
                              Between {loanLimits?.minExtensionInterestRate}% -
                              {loanLimits?.maxExtensionInterestRate}%
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className=' text-grey'>
                          Overdue Interest Rate(APR%)
                          <input
                            max={loanLimits?.maxOverDueIntRate}
                            min={loanLimits?.minOverDueIntRate}
                            placeholder='Enter Percentage'
                            value={this.state.overdueIntRate}
                            required
                            type='number'
                            onChange={(e) =>
                              this.setState({ overdueIntRate: e.target.value })
                            }
                          />
                          <div className='limit-parameters'>
                            Between {loanLimits?.minOverDueIntRate}% -
                            {loanLimits?.maxOverDueIntRate}%
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row pl-4'>
                      <div className='col-md-3 pl-0'>
                        <div className=' text-grey'>
                          Setup Fees Upfront
                          <div className='col-md-12 d-flex '>
                            <input
                              value={true}
                              name='isSetupFeesUpfront'
                              type='radio'
                              onChange={(e) =>
                                this.setState({
                                  isSetupFeesUpfront: true,
                                })
                              }
                              checked={this.state.isSetupFeesUpfront === true}
                            />
                            <div>Yes</div>
                          </div>
                          <div className='col-md-12 d-flex'>
                            <input
                              value={false}
                              name='isSetupFeesUpfront'
                              type='radio'
                              onChange={(e) =>
                                this.setState({
                                  isSetupFeesUpfront: false,
                                })
                              }
                              checked={this.state.isSetupFeesUpfront === false}
                            />
                            <div>No</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 '>
                        <div className=' text-grey'>
                          Setup Fees
                          <input
                            max={loanLimits?.maxSetupFees}
                            placeholder='Enter Setup Fee '
                            value={this.state.setupFees}
                            type='number'
                            required
                            onChange={(e) =>
                              this.setState({ setupFees: e.target.value })
                            }
                          />
                          <div className='limit-parameters'>
                            Upto
                            {rupeeDecimalFormatter(
                              loanLimits?.maxSetupFees
                            )}{' '}
                            (One time , including GST)
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 '></div>
                    </div>

                    <div className='row pl-4'>
                      <div className='col-md-3 pl-0'>
                        <div className=' text-grey'>
                          Subvention
                          <div className='col-md-12 d-flex '>
                            <input
                              value='Yes'
                              name='subvention'
                              type='radio'
                              checked={subventionFlag === 'Yes'}
                            />
                            <div>Yes</div>
                          </div>
                          <div className='col-md-12 d-flex'>
                            <input
                              value='No'
                              name='subvention'
                              type='radio'
                              checked={subventionFlag === 'No'}
                            />
                            <div>No</div>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-3 '>
                        <div className=' text-grey'>
                          Subvention Period
                          <input
                            placeholder='Enter Days '
                            value={this.state.subventionPeriodInDays}
                            type='number'
                            required
                            onChange={(e) =>
                              this.setState({
                                subventionPeriodInDays: e.target.value,
                              })
                            }
                            disabled={subventionFlag === 'Yes' ? false : true}
                          />
                          <div className='limit-parameters'>
                            Between {loanLimits?.subvention?.periodInDays?.min}{' '}
                            days -{loanLimits?.subvention?.periodInDays?.max}{' '}
                            days
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 '>
                        <div className=' text-grey'>
                          Subvention Interest Rate(APR%)
                          <input
                            max={loanLimits?.subvention?.rateOfInt?.max}
                            min={loanLimits?.subvention?.rateOfInt?.min}
                            placeholder=' Enter Percentage'
                            type='number'
                            required
                            step={0.1}
                            value={this.state.subventionInterestRate}
                            onChange={(e) =>
                              this.setState({
                                subventionInterestRate: e.target.value,
                              })
                            }
                            disabled={subventionFlag === 'Yes' ? false : true}
                          />
                          <div className='limit-parameters'>
                            Between {loanLimits?.subvention?.rateOfInt?.min}% -
                            {loanLimits?.subvention?.rateOfInt?.max}%
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 '>
                        <div className=' text-grey'>
                          Subvention Bearer
                          <input
                            step={0.1}
                            value={
                              subventionFlag === 'Yes'
                                ? loanLimits?.subvention?.bearer === 'seller'
                                  ? `Seller`
                                  : loanLimits?.subvention?.bearer === 'buyer'
                                  ? `Buyer`
                                  : loanLimits?.subvention?.bearer ===
                                    'third_party'
                                  ? `Third Party`
                                  : null
                                : ''
                            }
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className='col-md-3 '></div>
                      <div className='col-md-3 '>
                        <div className=' text-grey'>
                          Interest Collected
                          <input
                            step={0.1}
                            value={
                              subventionFlag === 'Yes'
                                ? loanLimits?.subvention?.collection ===
                                  'upfront'
                                  ? `Upfront`
                                  : loanLimits?.subvention?.collection ===
                                    'rear_ended'
                                  ? `Rear-Ended`
                                  : null
                                : ''
                            }
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row pl-4'>
                      <div className='col-md-3 pl-0'>
                        <div className=' text-grey'>
                          Margin
                          <div className='col-md-12 d-flex '>
                            <input
                              value='Yes'
                              name='marginFlag'
                              type='radio'
                              checked={marginFlag === 'Yes'}
                            />
                            <div>Yes</div>
                          </div>
                          <div className='col-md-12 d-flex'>
                            <input
                              value='No'
                              name='marginFlag'
                              type='radio'
                              checked={marginFlag === 'No'}
                            />
                            <div>No</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 '>
                        <div className=' text-grey'>
                          Margin Percentage
                          <input
                            max={loanLimits?.margin?.perMargin?.max}
                            min={loanLimits?.margin?.perMargin?.min}
                            placeholder='Enter Percentage '
                            value={this.state.marginPercentage}
                            type='number'
                            required
                            onChange={(e) =>
                              this.setState({
                                marginPercentage: e.target.value,
                              })
                            }
                            disabled={marginFlag === 'Yes' ? false : true}
                          />
                          <div className='limit-parameters'>
                            Between {loanLimits?.margin?.perMargin?.min}% -
                            {loanLimits?.margin?.perMargin?.max}%
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <button className='submit_button' type='submit'>
                        Activate Offer
                      </button>
                    </div>
                  </form>
                </div>
              </>
            ) : applicationStatus === 'accepted' ||
              applicationStatus === 'approved' ? (
              <>
                <div className='row main-row border-grey '>
                  <div className='col-md-3 main-row_inner'>
                    Limit Validity
                    <div className='text-bold'>
                      {activeLoans?.limitTenureInDays ?? '-'} days
                    </div>
                  </div>

                  <div className='col-md-3 main-row_inner'>
                    Standard Tenure
                    <div className='text-bold'>
                      {activeLoans?.tenure ?? '-'} days
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className=' text-grey'>
                      Min Invoice Amount
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(activeLoans?.minInvoiceAmount)}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className=' text-grey'>
                      Max Invoice Amount
                      <div className='text-bold'>
                        {rupeeDecimalFormatter(activeLoans?.maxInvoiceAmount)}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className=' text-grey'>
                      Status
                      <div className='text-bold'>
                        {activeLoans?.status ?? '-'}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Sanction Date
                    <div className='text-bold'>
                      {activeLoans?.sanctionDate ?? '-'}
                    </div>
                  </div>
                  {applicationStatus === 'accepted' ? (
                    <div className='col-md-2'>
                      <div className='limit_button' />
                      <Button
                        variant='contained'
                        disabled={!regenerateCheck}
                        onClick={() =>
                          this.toggleModal(UnlockLimitModal, 'UnlockLimitModal')
                        }
                      >
                        Regenerate
                      </Button>
                    </div>
                  ) : null}
                </div>

                <div className='row main-row border-grey '>
                  <div className='col-md-3 main-row_inner'>
                    Interest Rate
                    <div className='text-bold'>
                      {activeLoans?.interestRate || '-'} %
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Grace Period
                    <div className='text-bold'>
                      {`${activeLoans?.gracePeriodInDays} days` || '-'}
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Repayment Settlement Sub Order
                    <div className='text-bold'>
                      {this.settlementSubOrders(
                        activeLoans?.settlementSubOrder
                      ) || '-'}
                    </div>
                  </div>
                </div>

                <div className='row main-row'>
                  <div className='col-md-3 main-row_inner'>
                    Extension
                    <div className='text-bold'>
                      {activeLoans?.extension || '-'} days
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Extension Interest Rate(APR%)
                    <div className='text-bold'>
                      {activeLoans?.extensionInterestRate || '-'} %
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Overdue Interest Rate(APR%)
                    <div className='text-bold'>
                      {activeLoans?.overdueIntRate || '-'} %
                    </div>
                  </div>
                </div>
                <div className='row main-row border-grey'>
                  <div className='col-md-3 main-row_inner'>
                    Setup Fees Upfront
                    <div className='text-bold'>
                      {this.isSetupFeesUpfront(activeLoans?.isSetupFeesUpfront)}
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Setup Fees
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(activeLoans?.setupFees)}
                    </div>
                  </div>
                  {/* <div className='col-md-3 main-row_inner'>
                    Limit Maintanence
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(activeLoans?.limitMaintancefees)}
                    </div>
                  </div> */}
                </div>

                <div className='row main-row border-grey'>
                  <div className='col-md-3 main-row_inner'>
                    Subvention
                    <div className='text-bold'>
                      {activeLoans?.isSubvented ? 'Yes' : 'No'}
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Subvention Period
                    <div className='text-bold'>
                      {activeLoans?.subventionPeriodInDays || '-'} days
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Subvention Interest Rate(APR%)
                    <div className='text-bold'>
                      {activeLoans?.subventionIntRate || '-'}%
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Subvention Bearer
                    <div className='text-bold'>
                      {activeLoans?.subventionBearer === 'seller'
                        ? `Seller`
                        : activeLoans?.subventionBearer === 'buyer'
                        ? `Buyer`
                        : activeLoans?.subventionBearer === 'third_party'
                        ? `Third Party`
                        : '-'}
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Interest Collected
                    <div className='text-bold'>
                      {activeLoans?.interestCollection === 'upfront'
                        ? `Upfront`
                        : activeLoans?.interestCollection === 'rear_ended'
                        ? `Rear-Ended`
                        : '-'}
                    </div>
                  </div>
                </div>
                <div className='row main-row'>
                  <div className='col-md-3 main-row_inner'>
                    Margin
                    <div className='text-bold'>
                      {activeLoans?.isMargined ? 'Yes' : 'No'}
                    </div>
                  </div>
                  <div className='col-md-3 main-row_inner'>
                    Margin Percentage
                    <div className='text-bold'>
                      {activeLoans?.marginPercentage || '-'}%
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <div className='card mt-0'>
                <h3 className='card_heading'>Offer History</h3>
                <ReactTable
                  data={allOffers}
                  columns={applicationColumns}
                  defaultPageSize={10}
                  resizable={false}
                  showPagination={false}
                  minRows={3}
                  getTrProps={this.rowClicked}
                />
              </div>
            </div>
          </div>
          {applicationStatus === 'accepted' ||
          applicationStatus === 'approved' ? (
            <div className='close_button'>
              <div className='close_button_inner'>Close Limit</div>
            </div>
          ) : null}

          <CredoModal
            isOpen={isModalOpen}
            styles={
              modalName === 'UnlockLimitModal'
                ? {
                    content: {
                      minWidth: '30vw',
                      margin: '5rem auto',
                      height: '30vh',
                    },
                  }
                : {
                    content: {
                      minWidth: '70vw',
                      margin: '5rem auto',
                      height: '80vh',
                    },
                  }
            }
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            stateData={this.state}
            propsData={this.props}
          />
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getActiveLoan,
      loaderModal,
    },
    dispatch
  ),
});

const mapStateToProps = ({ offerReducer, leadManagementNavsReducer }) => ({
  activeLoans: offerReducer.activeLoans,
  promoterData: leadManagementNavsReducer.promoterData,
  loanLimits: offerReducer.loanLimits,
  allOffers: offerReducer.allOffers,
  offerHistory: offerReducer.offerHistory,
  regenerateCheck: offerReducer.regenerateCheck,
});

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
